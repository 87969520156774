.entryhall-locked-account {
    width: 520px;
}

.entryhall-form-sign-in {
    width: 300px;

    margin-top: $x;

    .entryhall-card-header {
        .entryhall-card-header-buttons {
            #saml_sign_in_anchor_button {
                margin-top: $x * 10;
                margin-bottom: $x * 3;
            }
        }
    }
    .entryhall-card-body {
        .entryhall-signin-email-input,
        .entryhall-signin-password-input {
            label {
                margin-bottom: 0;
            }
        }

        .entryhall-signin-email-input {
            margin-bottom: $x * 3;
        }

        .entryhall-signin-password-input {
            margin-bottom: $x * 6;
        }
    }
    .entryhall-card-footer {
        flex-direction: column;
        a {
            margin: ($x * 3) 0;
            text-align: center;
        }
        section.extra-info {
            gap: $x;
            display: inline-flex;
            justify-content: center;
            padding-top: $x * 3;
            border-top: 1px solid $color-line-rule;
            span,
            a {
                margin: 0;
            }
        }
    }
}

.entryhall-form-saml-sign-in {
    @extend .entryhall-form-sign-in;

    .entryhall-card-header {
        margin-top: $x;
        margin-bottom: $x;

        h3.entryhall-card-header-title {
            margin-top: $x * 10;
            margin-bottom: $x * 4;
            text-align: center;
        }
    }

    .entryhall-card-footer {
        .entryhall-or-separator {
            margin-top: $x * 3;
            margin-bottom: $x * 5;
        }
    }
}
