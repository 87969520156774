@use 'sass:math';

.entryhall-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 358px;

    @include xsmall-devices-down {
        width: 100vw;
    }

    font-size: $x * 2;
    color: $footerFontColor;

    .entryhall-footer-links {
        display: flex;
        flex-direction: row;
        gap: $x * 3;

        @include small-devices-down {
            gap: $x * 2;
        }

        & a {
            font-weight: 400;
            color: $footerFontColor;
            text-decoration: none;
            text-align: center;
        }

        .entryhall-footer-link-ad-preference-image {
            margin-top: -3px;
            margin-right: math.div($x, 2);

            @include small-devices-down {
                margin-right: math.div($x, 2);
            }
        }
    }

    .entryhall-footer-copyright {
        text-align: center;
        margin-top: 15px;
    }
}
