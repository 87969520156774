.inline-feedback {
    margin-left: 3px;

    .btn-link &.ar-icon-success {
        color: $color-success;
    }
    .btn-link &.ar-icon-danger {
        color: $color-error;
    }

    &.non-btn-feedback {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 6px;
        margin-right: 0px;
    }
}
