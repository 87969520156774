.entryhall-form-tfa {
    width: 390px;

    @include small-devices-down {
        width: 90vw;
    }

    .entryhall-card-header {
        height: $x * 20;
        margin-bottom: $x * 6;

        p.entryhall-card-header-title {
            margin-bottom: $x * 4;
        }

        p.entryhall-error-text {
            margin: 0;
        }
    }

    .entryhall-card-footer {
        flex-direction: column;
        margin-top: $x * 2;
        a {
            margin: ($x * 3) 0;
            text-align: center;
        }
        section {
            &.entryhall-tfa-actions {
                display: flex;
                flex-direction: row;
                justify-content: right;
                gap: $x * 3;
            }
            &.entryhall-tfa-help {
                margin-top: $x * 5;

                .entryhall-tfa-help-title {
                    font-weight: 600;
                }
            }
            &.entryhall-tfa-sms-actions {
                span.entryhall-tfa-code-not-received {
                    margin-right: $x;
                }
                button {
                    &:focus {
                        border: 0;
                    }
                    padding: 0;
                }
            }
        }
    }

    &.entryhall-tfa-init-error {
        margin-top: $x * 4;
        margin-bottom: $x * 4;
    }
}

.entryhall-page-sms-tfa-setup {
    .entryhall-card-header {
        height: $x * 26;
        margin-bottom: $x * 5;

        p.entryhall-card-header-title {
            margin-bottom: $x * 5;
        }

        p.entryhall-error-text {
            margin: 0;
        }
    }

    .entryhall-form-sms-tfa-setup {
        width: 370px;

        @include small-devices-down {
            width: 90vw;
        }
    }

    .entryhall-card-footer {
        flex-direction: column;
        a {
            margin: ($x * 3) 0;
            text-align: center;
        }
        section {
            &.entryhall-tfa-actions {
                display: flex;
                flex-direction: row;
                justify-content: right;
                gap: $x * 3;
            }
            &.entryhall-tfa-sms-actions {
                margin-top: $x * 4;
                padding: 0;
                line-height: 1;
                text-align: center;
                span {
                    margin: 0;
                    padding-right: 3px;
                }
                button {
                    &:focus {
                        border: 0;
                    }
                    padding-left: 3px;
                    padding-right: 0;
                }
            }
        }
    }
}

.entryhall-page-email-tfa-setup {
    .entryhall-card-header {
        height: $x * 10;
        margin-bottom: $x * 5;

        p.entryhall-card-header-title {
            margin-bottom: $x * 2;
        }

        p.entryhall-error-text {
            margin: 0;
        }
    }

    .entryhall-card-footer {
        flex-direction: column;
        a {
            margin: ($x * 3) 0;
            text-align: center;
        }
        section {
            &.entryhall-tfa-actions {
                display: flex;
                flex-direction: row;
                justify-content: right;
                gap: $x * 3;
            }
        }
    }
}

.entryhall-page-tfa-methods {
    .entryhall-card-2 {
        width: 480px;

        @include small-devices-down {
            width: 90vw;
        }

        .entryhall-card-header {
            height: $x * 30;
            margin-bottom: $x * 6;

            p.entryhall-card-header-title {
                margin-bottom: $x * 6;
            }
        }

        .entryhall-tfa-methods-list {
            display: flex;
            gap: $x * 2;

            i.fa-check-circle {
                color: $color-product;
                position: absolute;
                top: $x;
                right: -$x;
            }

            .disabled-link {
                pointer-events: none;
            }
        }
    }
}

.entryhall-form-app-tfa-setup {
    ol {
        margin-top: 20px;
        list-style: none;
        counter-reset: item;

        li {
            counter-increment: item;
            padding: 5px;
            margin-bottom: 12px;
            &::before {
                margin-left: -40px;
                margin-top: -5px;
                content: counter(item);
                position: absolute;
                background: $color-product;
                padding: 6px 13px;
                border-radius: 100%;
                color: white;
                text-align: center;
            }
        }
    }

    .thumbnail {
        width: 234px;
        height: 234px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
        }
    }

    .entryhall-card-footer {
        flex-direction: column;
        section {
            &.entryhall-card-actions {
                display: flex;
                flex-direction: row;
                justify-content: right;
                gap: $x * 3;
            }
        }
    }
}
