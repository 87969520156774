// Overrides

.ar-input-group-form-group .ar-input-group-group .ar-input-group-input {
    border-radius: 5px;
}

.ar-phone-input {
    .ar-input-group-form-group .ar-input-group-group .ar-input-group-input {
        border-radius: 0;
    }
}

// Force the NextRoll cookie constent icon to stay above the reCaptcha badge
#nextroll_consent_persistent_icon {
    margin-top: -125px;
}

// Globals

.inline-anchor {
    margin-top: -2px;
}

.entryhall-grey-button {
    color: $color-font;
}

.entryhall-error-text {
    color: $color-error;
}

input[type='password'] {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

// ATM this is only used by signup
.entryhall-select {
    .ar-select-button {
        border-radius: 5px; // to align with the input fields
        padding-left: $x * 2;
    }

    &.no-selected {
        .ar-select-button {
            color: $inputColorPlaceholder !important;
        }
    }
    &.has-error {
        .ar-select-button {
            border-color: $color-error;
        }
    }

    .ar-select-search-input {
        width: auto;

        .ar-input-group-addon {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:not(.ar-input-group-form-group-focused) {
            .ar-input-group-addon {
                border: none;
            }
        }

        .ar-input-group-input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
