@mixin title-typography {
    color: #3c4858;
    font-family: 'Work Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
}

section.entryhall-page-signup-exp1 {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;

    padding: 40px 80px;
    display: flex;
    flex-direction: column;

    background-color: #303273;
    background-image: linear-gradient(
            145deg,
            #ffffff 0%,
            #ffffff 47%,
            #ffffff00 47%,
            #ffffff00 100%
        ),
        url('../assets/signup-experiment-1/background-pattern.png');
    background-size: contain;

    @include cols2-medium-devices-down {
        padding: 10px 10px;
    }

    div.signup-exp1-header {
        margin-bottom: 36px;

        div.ar-style-base-logo-wrapper {
            svg {
                width: 130px !important;
                height: 24px !important;
            }
        }
    }

    div.signup-exp1-content {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        div.signup-exp1-content-left {
            width: 50%;

            @include cols2-medium-devices-down {
                display: none;
            }

            h1 {
                padding-top: 22px;
                @include title-typography;
            }

            div.signup-exp1-content-description {
                p {
                    margin-top: 19px;
                    flex-shrink: 0;
                    color: #3c4858;
                    font-family: 'Open Sans';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
                ul {
                    margin: 40px 10px;
                    padding: 4px;
                    color: #3c4858;
                    font-family: 'Open Sans';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;

                    background: rgba(255, 255, 255, 0.6);
                    backdrop-filter: blur(10px);
                    border-radius: 10px;
                }
            }

            div.signup-exp1-content-medals {
                margin-top: 49px;
                display: flex;
                flex-direction: column;
                justify-content: left;

                ul {
                    margin-bottom: 18px;
                    padding-left: 6px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: left;
                    list-style-type: none;

                    li {
                        padding: 0;
                        margin: 0 5px 0 0;
                    }
                }
            }
        }

        div.signup-exp1-content-right {
            width: 50%;
            @include cols2-medium-devices-down {
                width: 100%;

                h1 {
                    display: block !important;
                    font-size: 34px !important;
                    margin-bottom: 15px !important;
                }
            }

            h1 {
                display: none;
                @include title-typography;
            }

            div.entryhall-form-sign-up {
                margin: 0 auto;
                max-width: 541px;
                border: 1px solid #c7ccd2;
                background: #f2f3f2;
                padding: 42px;

                div.entryhall-signup-title {
                    display: none;
                }

                .google-icon {
                    margin-top: 6px;
                }

                .entryhall-or-separator {
                    span {
                        background-color: #f2f3f2;
                    }
                }

                i.form-control-feedback {
                    display: none;
                }

                input,
                div.ar-select-button {
                    padding: 15px 19px;
                    border-radius: 4px;
                    border: 1px solid inherit;
                    background: #fff;
                    font-family: 'Open Sans';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    height: auto;
                }

                div.ar-password-input-input-group {
                    input {
                        border-radius: 4px 0 0 4px;
                    }
                    span.input-group-addon {
                        &::before {
                            content: none;
                        }
                        width: 49px;
                        border-radius: 0 4px 4px 0;
                    }
                }

                div.entryhall-form-signup-tos {
                    margin: 21px 0;
                    padding: 0 40px;
                    color: #3c4858;
                    text-align: center;
                    font-family: 'Open Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;

                    a {
                        color: #00aeef;
                        font-family: 'Open Sans';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 21px;
                    }
                }

                button {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    border-radius: 4px;
                    background: #ff492c;

                    color: #fff;
                    text-align: center;
                    font-family: 'Open Sans';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 21px;
                }

                section.extra-info {
                    padding-top: 30px;
                    color: #3c4858;
                    text-align: center;
                    font-family: 'Open Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                }
            }
        }
    }

    div.signup-exp1-footer {
        padding-top: 79px;
        display: flex;
        flex-direction: column;

        @include cols2-medium-devices-down {
            padding-top: 20px !important;
        }

        div.signup-exp1-footer-logos {
            display: flex;
            justify-content: center;
            align-items: center;

            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                list-style-type: none;

                background: rgba(48, 50, 115, 0.6);
                backdrop-filter: blur(10px);
                border-radius: 10px;
                padding: 0 10px;

                li {
                    margin: 10px 15px;
                }
            }
        }
        div.signup-exp1-footer-bottom {
            margin-top: 53px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
