.entryhall-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: $x * 3;
}

.entryhall-card {
    padding: $x * 5;
    padding-bottom: $x * 5 * 2;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;

    .entryhall-card-header {
        min-height: $x * 10;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .entryhall-error-text {
            display: block;
            text-align: left;
        }

        p.entryhall-card-header-title {
            font-weight: 600;

            i {
                margin-right: $x * 2;

                &.ar-icon-success {
                    color: $color-success;
                }
                &.ar-icon-danger {
                    color: $color-error;
                }
            }
        }
    }

    .entryhall-card-footer {
        display: flex;
        justify-content: right;
    }
}

.entryhall-card-1 {
    @extend .ar-card;
    @extend .elevation-1;
    @extend .ar-card-body;
    @extend .entryhall-card;

    .entryhall-card-header {
        margin-bottom: $x * 3;
    }
}

.entryhall-card-2 {
    @extend .ar-card;
    @extend .elevation-1;
    @extend .ar-card-body;
    @extend .entryhall-card;

    padding-bottom: $x * 8;

    .entryhall-card-header {
        margin-bottom: $x * 5;
    }

    .entryhall-card-body {
        margin-bottom: $x * 5;
    }
}

.entryhall-card-block {
    @extend .entryhall-card;
    padding: 0;

    .entryhall-card-header {
        margin-bottom: 0;

        .entryhall-card-header-buttons {
            display: flex;
            flex-direction: column;
        }
        .entryhall-card-header-error {
            display: flex;
            flex-direction: column;
        }

        p.entryhall-card-header-title {
            font-size: $x * 3;
            margin-bottom: 0;
        }
    }

    .ar-password-input:not(.with-help) {
        padding-bottom: 0;
    }
}

.entryhall-or-separator {
    width: 100%;
    height: 20px;
    margin-bottom: $x * 5;
    border-bottom: 1px solid $color-line-rule;
    text-align: center;
    text-transform: uppercase;
    padding-top: 8px;

    span {
        color: $color-label;
        font-size: $x * 2 + 2;
        background-color: #fff;
        padding: 0 $x * 3;
    }
}
