@mixin title-typography {
    color: #3c4858;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
}

section.entryhall-page-signup-exp2 {
    margin: -18px;
    width: 100vw;

    display: flex;
    flex-direction: row;
    align-items: stretch;

    div.signup-exp-2-content-left {
        width: 60%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 61px 0;

        @include cols2-medium-devices-down {
            display: none;
        }

        div.signup-exp2-header {
            div.ar-style-base-logo-wrapper {
                svg {
                    width: 130px !important;
                    height: 24px !important;
                }
            }
        }

        div.signup-exp2-chart {
            margin-top: 49px;
        }

        div.signup-exp2-description {
            margin-top: 35px;

            h1 {
                margin-bottom: 25px;
                @include title-typography;
            }
            p {
                color: #3c4858;
                text-align: center;
                font-family: 'Open Sans';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }

        div.signup-exp2-brands {
            margin-top: 100px;

            p {
                color: #3c4858;
                text-align: center;
                font-family: 'Open Sans';
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 100% */
            }
            ul {
                margin-top: 25px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                list-style-type: none;
                li {
                    margin: 10px 10px;
                }
            }
        }
    }

    div.signup-exp-2-content-right {
        width: 40%;
        padding: 40px 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        background-color: #303273;
        background-image: url('../assets/signup-experiment-2/background-pattern.png');
        background-size: 350%;

        @include cols2-medium-devices-down {
            width: 100% !important;

            div.signup-exp2-header {
                display: block !important;
                padding: 10px;

                background: rgba(255, 255, 255, 0.6);
                backdrop-filter: blur(10px);
                border-radius: 10px;
            }

            h1 {
                display: block !important;
            }

            div.entryhall-form-sign-up {
                padding: 19px !important;
            }
        }

        div.signup-exp2-header {
            display: none;
        }

        h1 {
            display: none;
            margin-top: 25px;
            @include title-typography;
            color: #fff;
            font-size: 30px !important;
        }

        div.entryhall-form-sign-up {
            margin: 0 auto;
            padding: 50px 20px;
            max-width: 498px;

            div.entryhall-signup-title {
                display: none;
            }

            .entryhall-google-signup-g-info {
                color: #fff;
            }

            .google-icon {
                margin-top: 6px;
            }

            .entryhall-or-separator {
                span {
                    color: #fff;
                    background-color: #303273;
                }
            }

            i.form-control-feedback {
                display: none;
            }

            input,
            div.ar-select-button {
                padding: 15px 19px;
                border-radius: 4px;
                background: #fff;
                font-family: 'Open Sans';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                height: auto;
            }

            div.ar-password-input-input-group {
                input {
                    border-radius: 4px 0 0 4px;
                }
                span.input-group-addon {
                    &::before {
                        content: none;
                    }
                    width: 49px;
                    border: none;
                    background: lightgray;
                    border-radius: 0 4px 4px 0;
                }
            }

            div.entryhall-form-signup-tos {
                margin: 21px 0;
                padding: 0 40px;
                color: #fff;
                text-align: center;
                font-family: 'Open Sans';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;

                a {
                    color: #00aeef;
                    font-family: 'Open Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 21px;
                }
            }

            button {
                border: none;
                margin-top: 10px;
                padding-top: 15px;
                padding-bottom: 15px;
                border-radius: 4px;
                background: #ff492c;

                color: #fff;
                text-align: center;
                font-family: 'Open Sans';
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 21px;

                &:disabled {
                    background: #fd9e8f;
                    opacity: 1;
                }
            }

            section.extra-info {
                border: none;
                padding-top: 30px;
                color: #fff;
                text-align: center;
                font-family: 'Open Sans';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;

                a {
                    color: #00aeef;
                    font-family: 'Open Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 21px;
                }
            }
        }
        div.entryhall-footer {
            a {
                color: #fff;
                text-align: center;
                font-family: 'Open Sans';
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            p {
                color: #fff;
                text-align: center;
                font-family: 'Open Sans';
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.1px;
            }
        }
    }
}
