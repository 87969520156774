.entryhall-form-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    // This height is arbitrary, it is just visually similar to the signin and tfa form height.
    height: 34vh;
    @include small-devices-down {
        height: 80vh;
    }
}
