@import '@nextroll/ar-password-input/dist/scss/ar-password-input';
@import '@nextroll/ar-phone-input/dist/scss/ar-phone-input';

@import '_vars';
@import 'components/_header';
@import 'components/_footer';
@import 'components/_form-spinner';
@import 'components/_inline-alert';
@import 'components/_inline-feedback';

@import '_layout';
@import '_globals';

// Pages

.entryhall-page-locked-account,
.entryhall-page-sign-in,
.entryhall-page-sign-up,
.entryhall-page-password-reset-init,
.entryhall-page-password-reset-save,
.entryhall-page-password-expired,
.entryhall-page-tfa,
.entryhall-page-email-tfa-setup,
.entryhall-page-sms-tfa-setup,
.entryhall-page-tfa-methods,
.entryhall-page-app-tfa-setup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@import 'sections/_password';
@import 'sections/_signin';
@import 'sections/_tfa';
@import 'sections/_signup';
@import 'sections/_error';
