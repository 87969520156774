.entryhall-page-password-reset-init {
    .entryhall-form-password-reset-init {
        width: 450px;

        @include small-devices-down {
            width: 90vw;
        }
    }

    .entryhall-feedback-password-reset-init {
        width: 510px;

        @include small-devices-down {
            width: 90vw;
        }
    }
}

.entryhall-page-password-reset-save {
    .entryhall-form-password-reset-save {
        width: 351px;

        @include small-devices-down {
            width: 90vw;
        }
    }

    .entryhall-feedback-password-reset-save {
        width: 488px;

        @include small-devices-down {
            width: 90vw;
        }
    }

    .entryhall-feedback-password-reset-save-invalid {
        width: 520px;

        @include small-devices-down {
            width: 90vw;
        }
    }
}

.entryhall-page-password-expired {
    .entryhall-form-password-expired {
        width: 520px;

        @include small-devices-down {
            width: 90vw;
        }
    }

    .entryhall-feedback-password-expired {
        width: 488px;

        @include small-devices-down {
            width: 90vw;
        }
    }
}
