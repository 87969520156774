// Sizes.
$x: 6px; // the unit used by the Figma design.

// Colors.
$footerFontColor: #b1b1b1;

$inputColorPlaceholder: #a5acb5;
$inputBackgroundColor: #f2f2f2;

// Media queries

// Includes all smartphones and some tables.
$smallDevicesMaxWidth: 568px;
// Includes iPhone5, Galaxy Fold.
$extraSmallDevicesMaxWidth: 370px;
// Includes tablets and some desktops.
// It is meant to be used on pages that have 2 columns.
$cols2MediumDevicesMaxWidth: 1024px;

@mixin small-devices-down {
    @media screen and (max-width: $smallDevicesMaxWidth) {
        @content;
    }
}

@mixin xsmall-devices-down {
    @media screen and (max-width: $extraSmallDevicesMaxWidth) {
        @content;
    }
}

@mixin cols2-medium-devices-down {
    @media screen and (max-width: $cols2MediumDevicesMaxWidth) {
        @content;
    }
}
