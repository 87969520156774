.entryhall-page-sign-up {
    .entryhall-signup-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: $x * 5;
        margin-bottom: $x * 3;

        .entryhall-signup-title-header {
            font-weight: 600;
        }

        .entryhall-signup-title-package {
            font-weight: 500;
            margin-top: $x * 2;
        }
    }
}

.entryhall-form-sign-up {
    // This style only enforces the form minimal style
    // Page specific styles should be added in the page specific node

    max-width: 460px;
    margin-top: 0;
    margin-bottom: $x * 6;

    .entryhall-card-header {
        .entryhall-card-header-buttons {
            .entryhall-google-signup-g-info {
                padding: 15px 0;
                text-align: center;
            }
        }
    }

    .entryhall-google-signup {
        display: flex;
        justify-content: center;
    }

    .entryhall-card-body {
        flex-direction: column;

        .form-group {
            margin-bottom: $x * 4;
        }

        .google-icon {
            padding: 11px;
        }

        .entryhall-input-field-wrapper {
            width: 100%;
        }

        .ar-password-input:not(.with-help) {
            .ar-popper-wrapper {
                height: 50px;
            }
        }

        .entryhall-signup-field-names {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: $x * 3;

            @include small-devices-down {
                flex-direction: column;
                gap: 0;
            }

            div.form-group {
                width: 100%;
            }
        }

        .entryhall-input-password-placeholder {
            span.input-group-addon {
                border: none;
                border-radius: 5px;
            }
        }

        div.ar-select,
        div.ar-select-button {
            width: 100%;
            text-align: left;
        }
    }

    .entryhall-card-footer {
        flex-direction: column;
        a,
        .entryhall-form-signup-tos {
            margin: ($x * 2) 0;
            text-align: center;
        }
        section.extra-info {
            gap: $x;
            display: inline-flex;
            justify-content: center;
            padding-top: $x * 3;
            border-top: 1px solid $color-line-rule;
            span,
            a {
                margin: 0;
            }
        }
    }
}

@import '_signup-experiment-1';
@import '_signup-experiment-2';
