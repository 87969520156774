.entryhall-page-error {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @include small-devices-down {
        justify-content: center;
    }

    .entryhall-page-error-image {
        position: absolute;
        bottom: 52vh;
        width: 100vw;

        @media screen and (max-width: 1200px) {
            bottom: 56vh;
        }

        @include small-devices-down {
            display: none;
        }
    }

    .entryhall-page-error-main {
        display: flex;
        height: 60vh;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: $color-product;
        color: #fbfbfb;

        a {
            color: #fff;
            text-decoration: underline;
        }

        @include small-devices-down {
            height: 100vh;
        }

        .entryhall-page-error-card-block {
            @extend .entryhall-card-block;
            margin: 0;

            .entryhall-card-header {
                p.entryhall-card-header-title {
                    align-self: center;
                    font-size: $x * 7;
                    margin-bottom: $x * 5;
                }
            }

            .entryhall-card-body {
                margin: $x * 3;
            }
        }

        .entryhall-page-error-links {
            display: flex;
            width: 100vw;
            flex-direction: row;
            justify-content: center;
            gap: $x * 7;
            margin: $x * 3 0;

            a {
                text-decoration: underline;
                color: #fbfbfb;
            }
        }
    }
}
